// in the instance the main bundle fails to execute
// (e.g. from a browser specific syntax error) this fallback
// displays a user friendly screen
import image from '~/global/assets/images/error-banana.svg'

const doc = document.getElementsByTagName('html')
const app = document.getElementById('app')

window.setTimeout(() => {
    if (app && app.childElementCount === 0) {
        const backgroundColour =
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? '#000000' : '#ffffff'
        doc[0].setAttribute('style', `background-color: ${backgroundColour};`) // override scss/base styling for small screens
        app.innerHTML = `
            <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
                <h1>Something’s gone pear-shaped</h1>
                <img style="margin: 32px 0 24px;" src="${image}" alt="" />
                <p>Check that <a href="https://intercom.help/sharesies/en/articles/919998-web-browsers-supported-on-sharesies" target="_blank" rel="noopener noreferrer">your web browser is supported and up-to-date</a>. If you’re still having trouble, <a href="mailto:help@sharesies.com">email our investor care team</a>.</p>
            </div>`
    }
}, 1000) // wait a second for the app to potentially bootstrap before injecting any fallbacks
